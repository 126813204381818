import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
        {
            path: '/',
            redirect: {
                name: 'Home'
            }
        },
        {
            path: '/christmas-card-2020',
            name: 'Home',
            component: () => import('./views/Home.vue')
        },
        {
            path: '/christmas-card-2020/highlights',
            name: 'Highlights',
            component: () => import('./views/Highlights.vue')
        },
        {
            path: '/christmas-card-2020/all-the-deets',
            name: 'Details',
            component: () => import('./views/Details.vue')
        },
    ],

    router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    });

export default router;
